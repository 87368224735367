var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',_vm._b({staticClass:"d-flex flex-column",staticStyle:{"height":"100%"},attrs:{"loading":_vm.status.loading,"data-cy":"documents-show"}},'v-card',_vm.propsCompSheet,false),[(!_vm.documentId)?_c('v-card-text',[_c('p',[_vm._v(_vm._s(_vm.$t("empty")))])]):_vm._e(),(_vm.documentId)?[(_vm.isFullHtmlPage)?[_c('iframe',{staticClass:"iframeFullHtml",attrs:{"src":_vm.isLink ? _vm.documentDecoded() : _vm.documentEncoded()}})]:[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.document.name)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.clickBack}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),(_vm.viewAccessLog)?_c('v-btn',{attrs:{"icon":"","to":{ name: 'documents-access-show', params: { id: _vm.documentId } }}},[_c('v-icon',[_vm._v("mdi-list-box-outline")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":"","href":_vm.getUrlDownload(_vm.document.id)}},[_c('v-icon',[_vm._v("mdi-download")])],1),(
            _vm.document.fileType &&
            (_vm.authCanByUserId('editOwn.documents', _vm.document.ownerId) ||
              _vm.authCan('editOthers.documents'))
          )?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,2997477565)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{ name: 'documents-edit', params: { id: _vm.document.id } }}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("common.edit")))])],1),(
                _vm.authCanByUserId('deleteOwn.documents', _vm.document.ownerId) ||
                _vm.authCan('deleteOthers.documents')
              )?_c('v-list-item',{attrs:{"to":{ name: 'documents-delete', params: { id: _vm.document.id } }}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("common.delete")))])],1):_vm._e()],1)],1):_vm._e()],1),_c('v-card-text',[_c('div',{staticClass:"d-flex align-center mb-4"},[_c('v-icon',[_vm._v("mdi-file")]),_c('div',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.document.fileName))]),_c('v-icon',[_vm._v("mdi-account")]),_c('div',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.owner.name)+" "+_vm._s(_vm.owner.surnames))]),(_vm.document.private)?_c('v-chip',{staticClass:"mr-4",attrs:{"small":"","color":"primary"}},[_vm._v(_vm._s(_vm.$t("common.private")))]):_vm._e()],1),_c('p',[_vm._v(_vm._s(_vm.documentDescription))])]),_c('div',{staticClass:"flex-grow-1"},[(_vm.document.fileType === 'application/pdf')?_c('object',{attrs:{"data":_vm.documentFileUrl,"type":_vm.document.fileType,"width":"100%","height":"100%"}}):(_vm.document.fileType === 'image/png')?_c('img',{attrs:{"src":_vm.documentFileUrl,"width":"100%","height":"100%"}}):(_vm.document.fileType === 'image/jpeg')?_c('img',{attrs:{"src":_vm.documentFileUrl,"width":"100%","height":"100%"}}):(_vm.document.fileType === 'application/msword')?_c('object',{attrs:{"data":_vm.documentFileUrl,"type":_vm.document.fileType,"width":"100%","height":"100%"}}):(
            _vm.document.fileType ===
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          )?_c('object',{attrs:{"data":_vm.documentFileUrl,"type":_vm.document.fileType,"width":"100%","height":"100%"}}):_vm._e()])]]:_vm._e(),_c('v-alert',{attrs:{"dismissible":"","type":"error"},model:{value:(_vm.status.showReadError),callback:function ($$v) {_vm.$set(_vm.status, "showReadError", $$v)},expression:"status.showReadError"}},[_vm._v(" "+_vm._s(_vm.$t("documents.error"))+" ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }