<template>
  <v-card
    v-bind="propsCompSheet"
    :loading="status.loading"
    class="d-flex flex-column"
    style="height: 100%"
    data-cy="documents-show"
  >
    <v-card-text v-if="!documentId">
      <p>{{ $t("empty") }}</p>
    </v-card-text>

    <template v-if="documentId">
      <template v-if="isFullHtmlPage">
        <iframe
          :src="isLink ? documentDecoded() : documentEncoded()"
          class="iframeFullHtml"
        ></iframe>
      </template>
      <template v-else>
        <v-card-title>
          {{ document.name }}

          <v-spacer></v-spacer>

          <v-btn icon @click="clickBack">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-btn
            v-if="viewAccessLog"
            icon
            :to="{ name: 'documents-access-show', params: { id: documentId } }"
          >
            <v-icon>mdi-list-box-outline</v-icon>
          </v-btn>

          <v-btn icon :href="getUrlDownload(document.id)">
            <v-icon>mdi-download</v-icon>
          </v-btn>

          <v-menu
            offset-y
            v-if="
              document.fileType &&
              (authCanByUserId('editOwn.documents', document.ownerId) ||
                authCan('editOthers.documents'))
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn text icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                :to="{ name: 'documents-edit', params: { id: document.id } }"
              >
                <v-list-item-title>{{ $t("common.edit") }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="
                  authCanByUserId('deleteOwn.documents', document.ownerId) ||
                  authCan('deleteOthers.documents')
                "
                :to="{ name: 'documents-delete', params: { id: document.id } }"
              >
                <v-list-item-title>{{ $t("common.delete") }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>

        <v-card-text>
          <div class="d-flex align-center mb-4">
            <v-icon>mdi-file</v-icon>
            <div class="mr-4">{{ document.fileName }}</div>
            <v-icon>mdi-account</v-icon>
            <div class="mr-4">{{ owner.name }} {{ owner.surnames }}</div>
            <v-chip
              v-if="document.private"
              small
              color="primary"
              class="mr-4"
              >{{ $t("common.private") }}</v-chip
            >
          </div>
          <p>{{ documentDescription }}</p>
        </v-card-text>

        <div class="flex-grow-1">
          <object
            v-if="document.fileType === 'application/pdf'"
            :data="documentFileUrl"
            :type="document.fileType"
            width="100%"
            height="100%"
          ></object>
          <img
            v-else-if="document.fileType === 'image/png'"
            :src="documentFileUrl"
            width="100%"
            height="100%"
          />
          <img
            v-else-if="document.fileType === 'image/jpeg'"
            :src="documentFileUrl"
            width="100%"
            height="100%"
          />
          <object
            v-else-if="document.fileType === 'application/msword'"
            :data="documentFileUrl"
            :type="document.fileType"
            width="100%"
            height="100%"
          ></object>
          <object
            v-else-if="
              document.fileType ===
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            "
            :data="documentFileUrl"
            :type="document.fileType"
            width="100%"
            height="100%"
          ></object>
        </div>
      </template>
    </template>

    <v-alert v-model="status.showReadError" dismissible type="error">
      {{ $t("documents.error") }}
    </v-alert>
  </v-card>
</template>

<script>
import APP_CONSTANTS from "@/appConstants";
import ComponentStatus from "@/modules/base/componentStatus.mixin";
import Permissions from "@/modules/auth/mixins/Permissions.mixin";
import axios from "axios";

export default {
  name: "DocumentsShow",
  mixins: [ComponentStatus, Permissions],
  props: {
    documentId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    valid: false,

    viewAccessLog: true,

    documentBase64: "",
  }),
  computed: {
    // 2) When the document id changes we use the store getter to "get" the
    // object that has (or the temporary one that will have) the document data.
    // Note that this is different than the store action used to read the
    // actual data from the backend, they both work "in parallel".
    organisationAppearance() {
      return this.$store.getters["auth/getOrganisationAppearance"];
    },
    document() {
      return this.$store.getters["documents/readById"](this.documentId);
    },
    owner() {
      return this.$store.getters["users/readById"](this.document.ownerId);
    },
    documentDescription() {
      let documentDescription = "";
      if (this.document.description) {
        documentDescription = this.document.description;
        documentDescription = documentDescription.replace("#html", "");
        documentDescription = documentDescription.replace("#link", "");
      }
      return documentDescription;
    },
    documentUrl() {
      if (this.documentId) {
        let apiUrl = this.$store.getters["config/getApiUrl"];
        return `${apiUrl}/documents/${this.documentId}/download`;
      }
      return "";
    },
    documentFileUrl() {
      if (this.documentId) {
        let apiUrl = this.$store.getters["config/getApiUrl"];
        return `${apiUrl}/documents/${this.documentId}/downloadfile`;
      }
      return "";
    },
    isFullHtmlPage() {
      if (
        this.document &&
        this.document.fileClass &&
        (this.document.fileClass == "html" || this.document.fileClass == "link")
      ) {
        return true;
      }
      return false;
    },
    isLink() {
      if (
        this.document &&
        this.document.fileClass &&
        this.document.fileClass == "link"
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    // 3) If the document id changes and we have not edit any field we trigger
    // the store action to load the data. Note that this is different than
    // the getter used to display the data, they both work "in parallel".
    documentId: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          this.setStatusReading();
          this.$store
            .dispatch("documents/readById", newValue)
            .then(() => {
              return this.$store.dispatch(
                "users/readById",
                this.document.ownerId
              );
            })
            .then(() => {
              this.setStatusReadSuccess();
            })
            .catch((/* error */) => {
              this.setStatusReadError();
            });
        }
      },
    },
    organisationAppearance: {
      immediate: true,
      handler: function (newValue) {
        let userRole = this.$store.getters["auth/getUserRole"];

        if (newValue && "treeViewOptions" in newValue) {
          if (userRole === APP_CONSTANTS.USER_ROLES_USER_RW) {
            // View options
            if ("viewAccessLogUserRW" in newValue.treeViewOptions) {
              if (newValue.treeViewOptions.viewAccessLogUserRW === true) {
                this.viewAccessLog = true;
              } else {
                this.viewAccessLog = false;
              }
            }
          } else if (userRole === APP_CONSTANTS.USER_ROLES_USER_R) {
            // View options
            if ("viewAccessLogUserR" in newValue.treeViewOptions) {
              if (newValue.treeViewOptions.viewAccessLogUserR === true) {
                this.viewAccessLog = true;
              } else {
                this.viewAccessLog = false;
              }
            }
          }
        }
      },
    },
  },
  created() {},
  methods: {
    documentEncoded() {
      if (this.document.fileType && this.documentBase64) {
        return `data:${this.document.fileType};base64,${this.documentBase64}`;
      } else if (this.document.fileType) {
        let apiUrl = this.$store.getters["config/getApiUrl"];
        axios({
          method: "GET",
          url: `${apiUrl}/documents/${this.documentId}/base64`,
        }).then((result) => {
          this.documentBase64 = result.data;
          return `data:${this.document.fileType};base64,${this.documentBase64}`;
        });
      } else {
        return "";
      }
    },
    documentDecoded() {
      if (this.document.fileType && this.documentBase64) {
        return atob(this.documentBase64);
      } else if (this.document.fileType) {
        let apiUrl = this.$store.getters["config/getApiUrl"];
        axios({
          method: "GET",
          url: `${apiUrl}/documents/${this.documentId}/base64`,
        }).then((result) => {
          this.documentBase64 = result.data;
          return atob(this.documentBase64);
        });
      } else {
        return "";
      }
    },
    clickBack: function () {
      this.$router.go(-1);
    },
    getUrlDownload(itemId) {
      let apiUrl = this.$store.getters["config/getApiUrl"];
      return `${apiUrl}/documents/${itemId}/download`;
    },
  },
};
</script>

<style scoped lang="scss">
.iframeFullHtml {
  height: 100%;
  border: 1px none #000;
}
</style>
