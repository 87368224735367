<template>
  <div class="fill-height" data-cy="documents-show-page">
    <DocumentsShow :documentId="documentId"></DocumentsShow>
  </div>
</template>

<script>
import DocumentsShow from "../components/DocumentsShow.vue";

export default {
  name: "DocumentsShowPage",
  components: {
    DocumentsShow,
  },
  data: () => ({}),
  created() {},
  computed: {
    // 1) Computed property to set the document id.
    documentId() {
      let documentId = "";
      if (this.$route.params.id) {
        documentId = this.$route.params.id.toString();
      }
      return documentId;
    },
  },
};
</script>
